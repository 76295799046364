import $, { extend } from 'jquery';
import {React,useEffect} from 'react'
import {btnSetColor_CBack,TableDatePicker,FormatDate} from '../function_lib/common_lib';


export function Comp_Student_Tc_Rel(props){
    //alert(JSON.stringify(props.studentForTC));
    useEffect(()=>{
        if(typeof props.studentForTC.student_name !=="undefined"){
            
            // if(props.lsStudentBasic[0]["admit_date"]!==null){
            //     let strAdmitDate=FormatDate(props.lsStudentBasic[0]["admit_date"]);
            //     $("#admit_date").val(strAdmitDate);
            // }
            // if(props.lsStudentBasic[0]["dob"]!==null){
            //     let strDob=FormatDate(props.lsStudentBasic[0]["dob"]);
            //     $("#edit_dob").val(strDob);
            // }
            
            
        }

    },[props.studentForTC])
    return(<div className="modal fade bd-example-modal-xl"  id="divStudentTCRel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Student TC Process :{props.studentForTC.student_name}</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
       
            <form class="form-group" id="frmStudentTCRel" name="frmStudentTCRel">
                <input type="hidden" name="res_func" id="res_func" value="stubasic_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="submitStuTermination" />
                <input type="hidden" name="m_type" id="m_type" value="submitStuTermination" />
                <input type="hidden" name="stu_id" id="stu_id" value={btoa(props.studentForTC["student_admis_id"])}/>
                <input type="hidden" name="stu_termi_id" id="stu_termi_id" defaultValue={props.studentForTC["stu_termi_id"]!==null?btoa(props.studentForTC["stu_termi_id"]):"0"}/>
                
                

               
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">
                   
                            <div class="card-body">
                           {/* start card-body */}
                           <div className="row">
                                
                                 
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Scholar No.</label>
                                            <div className="input-group input-group-sm">
                                                <input readOnly={"true"} type="text" id="scholar_no" name="scholar_no" defaultValue={props.studentForTC["scholar_no"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Student Name</label>
                                            <div className="input-group input-group-sm">
                                                <input readOnly={"true"} type="text" id="student_name" name="student_name" defaultValue={props.studentForTC["student_name"]} className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>

                                  
                                  <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Father's Name</label>
                                          <div className="input-group input-group-sm">
                                              <input readOnly={"true"} type="text" defaultValue={props.studentForTC["father_name"]} id="father_name" name="father_name" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div className="input-group input-group-sm">
                                             <input readOnly={"true"} type="text" id="session_year" name="session_year" class="form-control form-control-sm" defaultValue={props.studentForTC["session_name"]}/>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Class</label>
                                            <div className="input-group input-group-sm">
                                            <input readOnly={"true"} type="text" id="class_name" name="class_name" class="form-control form-control-sm" defaultValue={props.studentForTC["class_name"]}/>
                                            </div>
                                        </div>
                                  </div>
                                 
                                
                                </div>
                                <div class="row">
                                
                               

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">TC Relesing Session</label>
                                            <div className="input-group input-group-sm">
                                            <select id="term_year_id" name="term_year_id" class="form-control form-control-sm required">
                                                <option value={"0"}>-select-</option>
                                                {props.lsSessionYear.map((item,index)=>{
                                                return <option selected={props.studentForTC["session_year_id"]===item["id"]} value={btoa(item["id"])}>{item["session_name"]}</option>
                                               })}
                                             </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">TC Relesing Class</label>
                                            <div className="input-group input-group-sm">
                                            <select id="term_class_id" name="term_class_id" class="form-control form-control-sm required">
                                                <option value={"0"}>-select-</option>
                                                {props.lsClassMaster.map((item,index)=>{
                                                return <option selected={props.studentForTC["class_id"]===item["id"]} value={btoa(item["id"])}>{item["class_name"]}</option>
                                               })}
                                             </select>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Termination</label>
                                            <div className="input-group input-group-sm">
                                             <select id="termination_type" name="termination_type" class="form-control form-control-sm required">
                                                <option value={"0"}>-select-</option>
                                                <option value={"TC"}>TC</option>
                                             </select>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">To School Name</label>
                                            <div className="input-group input-group-sm">
                                            <input  type="text" id="to_school" name="to_school" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">To Dise Code</label>
                                            <div className="input-group input-group-sm">
                                            <input  type="text" id="to_dise_code" name="to_dise_code" class="form-control form-control-sm"/>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description (if Any)</label>
                                            <div className="input-group input-group-sm">
                                            <textarea id="tc_description" name="tc_description" class="form-control form-control-sm">

                                            </textarea>
                                            
                                            </div>
                                        </div>
                                  </div>
                            
                                 
                                 
                                  
                                 
                                 
         
                                </div>  
                      
                    
                           {/* edn of card-body */}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" onClick={()=>props.submitTermination("frmStudentTCRel")} class="btn btn-primary _btnSetColor11">Submit Termination</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Student_Tc_Rel;