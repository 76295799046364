import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getCol3} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';
import Comp_Print_Report from '../components/comp_print_report';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';



let loginUser="";
let userObject="";
let companyName="";
let clsStudentAdmission;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
let _sumDebit=0;
let _sumCredit=0;
class View_Fee_Transactions extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListBranches:[],stListTransactions:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stAccLedger:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsStudentAdmission=this;
      }



      getStudentFeeTransaction(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getFeeTransactions"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        map.push({name:"ser_type",value:$("#ser_type").val()});
        map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        map.push({name:"ser_acc_ledger",value:$("#ser_acc_ledger").val()});
        map.push({name:"ser_branches",value:$("#ser_branches").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getFeeTransactions";
        CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                _sumCredit=0;
                _sumDebit=0;
                let listData=JSON.parse(data);
                var strData="";
                let vCredit=0;
                let vDebit=0;
                for(let i=0;i<listData.length;i++){
                  if(listData[i]["type"]==="Credit"){
                    vCredit+=parseFloat(listData[i]["value"]);
                  }else if(listData[i]["type"]==="Debit"){
                    vDebit+=parseFloat(listData[i]["value"]);
                  }
                }
                $(".outstanding_rpt").html(listData.length+" records found | Total Credit:<b>"+vCredit+"</b> | Total Debit:<b>"+vDebit+"</b>");
                clsStudentAdmission.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsStudentAdmission.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  


getMasters(){
  var map=[];
  map.push({name:"curl_type",value:"getMastersRequest"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"branch_acc",value:getCol3()});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getMastersRequest";
  CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          //alert(JSON.stringify(listData.master_balance));
          clsStudentAdmission.setState({
            stListSessionYear:listData.ls_session_year,
            stListClassMaster:listData.ls_class_master,
          stListFeeCategory:listData.ls_fee_category,
          stAccLedger:listData.ls_acc_ledger,
          stListBranches:listData.ls_branches},()=>{
            clsStudentAdmission.forceUpdateHandler();
          })
        }
  });
}
          
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getStudentFeeTransaction();
this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
{/* for now [row]it is unused */}
{/* starting of cols */}

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_session_year" name="ser_session_year" className="form-control form-control-sm">
                <option value={"0"}>-Session Year-</option>
                {this.state.stListSessionYear.map((item,index)=>{
                  return <option value={item["id"]}>{item["session_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_branches" name="ser_branches" className="form-control form-control-sm">
               {this.state.stListBranches.length>1?<option value={btoa("0")}>-All-</option>:''}
                {this.state.stListBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_class_name" name="ser_class_name" className="form-control form-control-sm">
                <option value={"0"}>-Classes-</option>
                {this.state.stListClassMaster.map((item,index)=>{
                  return <option value={item["id"]}>{item["class_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>
<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_acc_ledger" name="ser_acc_ledger" className="form-control form-control-sm">
                <option value={"0"}>-A/c Ledger-</option>
                {this.state.stAccLedger.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_type" name="ser_type" className="form-control form-control-sm">
                <option value={"0"}>-Type-</option>
                <option value={"Credit"}>-Credit-</option>
                <option value={"Debit"}>-Debit-</option>
               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-5 col-md-5 col-sm-5 float-right'>
<div className="form-group">
         <div className="input-group">
         <TableDatePicker refName="ser_from_date" />-To-
         <TableDatePicker refName="ser_to_date"/>
             </div>
         
         </div>
</div>

{/* 
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div> */}

<div className="col-lg-12 col-md-12 col-sm-12 pb-2 vcenter-item1 float-right">

               
              
               <div className='col-12 '>
                 <div onClick={()=>this.getStudentFeeTransaction()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <button style={{marginRight:"10px"}} class="btn btn-sm btn-primary ml-2"  type="button" data-toggle="modal" data-target="#divPrintReport" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>
                 <div className="btn btn-sm btn-danger">Reset</div>
                 
               </div>

</div>
{/* end of cols */}

    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Fee Transactions</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Scholar No</th>
                      <th>Student Name</th>
                      <th>Father's Name</th>
                      <th>Session Year</th>
                      {/* <th>Class Name</th> */}
                      <th>Ledger</th>
                      <th>Receipt No</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Payment Mode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                      if(index===0){
                        _sumDebit=0;
                        _sumCredit=0;
                      }
                      _sumDebit+=parseFloat(item["debit"]);
                      _sumCredit+=parseFloat(item["credit"]);
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item.fmt_date}</td>
                        <td>{item.scholar_no}</td>
                        <td>{item.student_name}</td>
                        <td>{item.father_name}</td>
                        <td>{item.session_name}</td>  
                        
                        <td>{item.acc_ledger}</td>
                        <td>{item.receipt_no}</td>  
                    <td>Rs.{item.debit}</td>    
                  <td>Rs.{item.credit}</td>
                  <td>{item.payment_mode}</td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                   
                     {/* <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentBasic" href="javascript:void(0)">View/Edit Basic Details</a>
                     <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal"  data-target="#divEditStudentFamily" href="javascript:void(0)">View/Edit Family Details</a>
                     <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">View/Edit Authorization</a> */}
                    </div>
                  </div>
                  </td>
                    </tr>)
                  }))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td><b>Rs.{_sumDebit}</b></td>
                    <td><b>Rs.{_sumCredit}</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Print_Report 
ptrColumnHeader={["Date","Scholar No.","Student Name","Father's Name","Session Year","Ledger","Receipt No","Debit","Credit","Payment Mode"]} 
ptrColumnName={["fmt_date","scholar_no","student_name","father_name","session_name","acc_ledger","receipt_no","debit","credit","payment_mode"]} 
lsTabelData={this.state.stListTransactions}

totals={[{"col":"debit","col_index":9,"total_value":0},
  {"col":"credit","col_index":10,"total_value":0}
  // {"col":"Net Amount","col_index":8,"total_value":0}
]}

ptrTitle={"Fee Transaction Entry"}
 logUser={userObject}
/>
   
    {/* <Comp_Class_Fee_Master lsSessionYear={this.state.stListSessionYear} lsClassMaster={this.state.stListClassMaster} lsFeeCategory={this.state.stListFeeCategory} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default View_Fee_Transactions;