import $, { extend } from 'jquery';
import React,{useEffect,useContext,useState} from 'react';
import Select from 'react-select';
export function CompStudent_Auto_Complete_New(props){
    const [selectedOption, setSelectedOption] = useState(null);
    const handleChange = (option) => {
      setSelectedOption(option);
      
      if(option !==null){
        props.compOnChange(btoa(option.id));
      }
      
      //alert(JSON.stringify(option));
      //onChange(option);
    };
    useEffect(()=>{
        setSelectedOption(null);
    },[props.studentList])
    
return(<><Select className={props.propClass}
    //inputId={props.target_obj}
     id={props.comp_id}
     name={props.comp_name}
    target_obj={props.target_obj}
    value={selectedOption}
    onChange={handleChange}
    options={props.studentList}
    getOptionLabel={(option) => option.student_name}
    getOptionValue={(option) => btoa(option.id)}
    placeholder={props.placeHolder}
    isClearable
  />
  <input type="hidden" name={props.target_obj} id={props.target_obj} 
  value={selectedOption ? btoa(selectedOption.id) : ''}
  />
  </>)
}
export default CompStudent_Auto_Complete_New;