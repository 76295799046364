import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getCol3} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';
import Comp_Student_Transport_Mapp from '../components/comp_student_transport_mapp';



let loginUser="";
let userObject="";
let companyName="";
let clsStudentAdmission;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
class View_Student_Transportation extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListBranches:[],stListClassFee:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stListTransp:[],stLsStudentBasic:[],stLsStudentFamily:[],stLsStudentAuth:[],stStudentId:"0",
      stEditItem:{}};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsStudentAdmission=this;
      }
//******  for child component function

editStudentBasic(frmName){
  btnSetColor_CBack(this,frmName,"updateStudentBasic",function(data,msg){
      if(data==="001"){
          alert("Updated Successfully");
          //clsStudentAdmission.getStudentAdmission();
      }
  })
}
editStudentFamily(frmName){
  btnSetColor_CBack(this,frmName,"updateStudentFamily",function(data,msg){
    if(data==="001"){
        alert("Updated Successfully");
        //clsStudentAdmission.getStudentAdmission();
    }
})
}
editStudentAuth(frmName){
  btnSetColor_CBack(this,frmName,"updateStudentAuth",function(data,msg){
    if(data==="001"){
        alert("Updated Successfully");
        //clsStudentAdmission.getStudentAdmission();
    }
})
}

//**** end of child component function



      getStudentAdmission(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getStudentAdmission"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        map.push({name:"ser_shift",value:$("#ser_shift").val()});
        //map.push({name:"ser_admission_type",value:$("#ser_admission_type").val()});
        //map.push({name:"ser_stu_cast_category",value:$("#ser_stu_cast_category").val()});
        //map.push({name:"ser_stu_gender",value:$("#ser_stu_gender").val()});
        map.push({name:"ser_transp_master",value:$("#ser_transp_master").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"ser_branches",value:$("#ser_branches").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getStudentAdmission";
        CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                $(".outstanding_rpt").html("Total :"+listData.length+" Records Found");
                
                clsStudentAdmission.setState({stListClassFee:listData},()=>{
                  //window.getPagination("#tbData");
                  clsStudentAdmission.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
      okSucc(data,smg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful","Record Added Successfully",true);
          clsStudentAdmission.getClassMaster();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }
stubasic_okSucc(data,msg){
  alert(data);
}      

onStudentDetailsEdit(item){
  let map=[];
  map.push({name:"curl_type",value:"getMastStudentData"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"user_type",value:userObject[0].type});
  map.push({name:"stu_id",value:btoa(item.id)});
  //alert($("#ser_session_year").val());
  //map.push({name:"upper_id",value:userObject[0].upper_id});
  map["res_func"]="callBackrrr";
  map["curl_type"]="getMastStudentData";
  CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      clsStudentAdmission.setState({stLsStudentBasic:lsData.ls_stu_basic,
      stLsStudentFamily:lsData.ls_stu_family,stLsStudentAuth:lsData.ls_stu_auth,
      stStudentId:item.id,stEditItem:item},()=>{
        clsStudentAdmission.forceUpdateHandler();
      })
    }
  })
}

getMasters(){
  var map=[];
  map.push({name:"curl_type",value:"getMastersRequest"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"branch_acc",value:getCol3()});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getMastersRequest";
  CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          //alert(JSON.stringify(listData.master_balance));
          clsStudentAdmission.setState({
            stListSessionYear:listData.ls_session_year,
            stListClassMaster:listData.ls_class_master,
          stListFeeCategory:listData.ls_fee_category,
        stListBranches:listData.ls_branches,
        stListTransp:listData.ls_transp},()=>{
            clsStudentAdmission.forceUpdateHandler();
          })
        }
  });
}
          
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getStudentAdmission();
this.getMasters();

$('#divStuTransMapp').on('hidden.bs.modal', function () {
  //window.location.reload();
  $("#frmStuTransMapp")[0].reset();
  clsStudentAdmission.setState({stLsStudentBasic:[],
    stLsStudentFamily:[],stLsStudentAuth:[],
    stStudentId:"0"},()=>{
      clsStudentAdmission.forceUpdateHandler();
    });
  clsStudentAdmission.getStudentAdmission();
});


}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
{/* for now [row]it is unused */}
{/* starting of cols */}

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_session_year" name="ser_session_year" className="form-control form-control-sm">
                <option value={"0"}>-Session Year-</option>
                {this.state.stListSessionYear.map((item,index)=>{
                  return <option value={item["id"]}>{item["session_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>
<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_branches" name="ser_branches" className="form-control form-control-sm">
               {this.state.stListBranches.length>1?<option value={btoa("0")}>-All-</option>:''}
                
                {this.state.stListBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_class_name" name="ser_class_name" className="form-control form-control-sm">
                <option value={"0"}>-Classes-</option>
                {this.state.stListClassMaster.map((item,index)=>{
                  return <option value={item["id"]}>{item["class_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_shift" name="ser_shift" className="form-control form-control-sm">
                <option value={"0"}>-Shift-</option>
                <option value={"First"}>-First-</option>
                <option value={"Second"}>-Second-</option>
               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_transp_master" name="ser_transp_master" className="form-control form-control-sm">
                <option value={"0"}>-All Student-</option>
                <option value={"-1"}>-Transport Student-</option>
                {this.state.stListTransp.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["vehicle_type"]}-{item["vehicle_number"]}</option>
                })}
                
               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='Student Name/Scholar No.' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getStudentAdmission()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>
</div>
{/* end of cols */}

    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Student List</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Student Name</th>
                      <th>Father's Name</th>
                      <th>Session Year</th>
                      <th>Class Name</th>
                      <th>Section</th>
                      <th>Shift</th>
                      <th>Tranp.Details</th>
                      <th>Driver Details</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListClassFee.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item.student_name}</td>
                        <td>{item.father_name}</td>
                        <td>{item.session_name}</td>  
                        <td>{item.class_name}</td>  
                        <td>{item.section}</td>
                        <td>{item.shift}</td>
                  <td>{item.vehicle_type}-{item.vehicle_number}</td>
                  <td>{item.driver_name}-{item.driver_contact_no}</td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                   
                     <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divStuTransMapp" href="javascript:void(0)">View/Edit Transportation</a>
                     <div class="dropdown-divider"></div>
                     {/* <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">Promot to Next</a>
                     <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">TC/SLC Process</a> */}

                    </div>
                  </div>
                  </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
    <Comp_Student_Transport_Mapp editItem={this.state.stEditItem} editStudentBasic={this.editStudentBasic} studentId={this.state.stStudentId} lsTransportList={this.state.stListTransp} lsStudentBasic={this.state.stLsStudentBasic} app_user_id={userObject[0].app_user_id} />
   
          </div>)
      }
}
export default View_Student_Transportation;