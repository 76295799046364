import React,{useEffect,useState} from 'react'

export function Comp_Edit_Student_Family(props){
    //alert(JSON.stringify(props.lsStudentFamily));
    const [stuFamData,setStuFamData]=useState({});
    useEffect(()=>{
       
        if(props.lsStudentFamily.length>0){
            setStuFamData(props.lsStudentFamily[0]);
        }
        if(props.lsStudentFamily.length===0){
            setStuFamData({});
        }
    
    },[props.lsStudentFamily])
    //alert(props.studentId);
  
    
    return(<div className="modal fade bd-example-modal-xl"  id="divEditStudentFamily" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Edit Family Details :{props.editItem.student_name}</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
            <form class="form-group" id="frmEditStudentFamily" name="frmEditStudentFamily">
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="updateStudentFamily" />
                <input type="hidden" name="m_type" id="m_type" value="updateStudentFamily" />
                <input type="hidden" name="fam_id" id="fam_id" value={typeof stuFamData.id!=="undefined"?btoa(stuFamData.id):"0"} />
                <input type="hidden" name="fam_student_id" id="fam_student_id" value={typeof stuFamData.student_id!=="undefined"?btoa(stuFamData.student_id):btoa(props.studentId)} />
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">

                            <div class="card-body">
                           {/* start card-body */}
                           {/* family members  */}
                                              
        <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title"><b>Parent's Detail</b></h3>

          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fa fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fa fa-times"></i></button>
          </div>
        </div>
       
        <div class="card-body">
          <div class="row">
         

                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Father's Occupation</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" id="father_occup" defaultValue={stuFamData["father_occup"]} name="father_occup" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div> 
                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Job Type</label>
                                          <div className="input-group input-group-sm">
                                              <select id="father_job_type" name="father_job_type" className="form-control">
                                                  <option value={""}>-Select Job Type-</option>
                                                  <option selected={stuFamData["father_job_type"]==="Private"} value={"Private"}>-Private Service-</option>
                                                  <option selected={stuFamData["father_job_type"]==="Government"} value={"Government"}>-Government Service-</option>
                                              </select>
                                          </div>
                                      </div>
                        </div> 
           
          </div>

          <div class="row">
          <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Mother's Name</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["mother_name"]} id="mother_name" name="mother_name" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Mother's Occupation</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["mother_occup"]} id="mother_occup" name="mother_occup" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div> 
                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Job Type</label>
                                          <div className="input-group input-group-sm">
                                              <select id="mother_job_type" name="mother_job_type" className="form-control">
                                                  <option value={""}>-Select Job Type-</option>
                                                  <option selected={stuFamData["mother_job_type"]==="Private"} value={"Private"}>-Private Service-</option>
                                                  <option selected={stuFamData["mother_job_type"]==="Government"} value={"Government"}>-Government Service-</option>
                                                  <option selected={stuFamData["mother_job_type"]==="House Wife"} value={"House Wife"}>-House Wife-</option>
                                              </select>
                                          </div>
                                      </div>
                        </div> 
           
          </div>
          {/* address */}
          <div class="row">
          <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Residential Address</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["resi_address"]} id="resi_address" name="resi_address" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Permanent Address</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["perm_address"]} id="perm_address" name="perm_address" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div> 
                        
           
          </div>
          {/* address */}
          
        </div>

      </div>
            
      {/* end of family members */}
                                
                              

                            {/* end of card-body */}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" onClick={()=>props.editStudentFamily("frmEditStudentFamily")} class="btn btn-primary _btnSetColor123">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Edit_Student_Family;