import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData,getCol3} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker
,GetImgCertUser_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsStudentBulkImport;

let rf="0";
class Student_Bulk_Import extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={stListBranches:[],stListClassFee:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stListAccLedger:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsStudentBulkImport=this;
      }
      
getMasters(){
    var map=[];
    map.push({name:"curl_type",value:"getMastersRequest"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"branch_acc",value:getCol3()});
    map["res_func"]="callBackrrr";
    map["curl_type"]="getMastersRequest";
    CallMapMethod_CBack(clsStudentBulkImport,map,function(data,msg){
      //alert(data);
        if(data!=="0" && data!==""){
            let listData=JSON.parse(data);
            var strData="";
            //alert(JSON.stringify(listData.master_balance));
            clsStudentBulkImport.setState({stListSessionYear:listData.ls_session_year,
              stListClassMaster:listData.ls_class_master,
            stListFeeCategory:listData.ls_fee_category,
            stListAccLedger:listData.ls_acc_ledger,
            stListBranches:listData.ls_branches},()=>{
                clsStudentBulkImport.forceUpdateHandler();
            })
          }
    });
  }
      okSucc(data,smg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful","Record Added Successfully",true);
          clsStudentBulkImport.getSessionYear();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }


      

saveWappCompaign(){
    GetImgCertUser_CBack(clsStudentBulkImport,"#frmStudentBulkImport","bulkImportStudent",function(checkData,checkMsg,userMsg){
      if(checkData==="-10"){
        alert("Kindly fill required fields");
        return;
      }else if(checkData==="001"){
        alert(checkMsg)
        //Custom_Alert("Successfully Updated",checkMsg,true);
        //$("#updResponsePro").html("File Uploaded Successfully")
          
      }else if(checkData==="01") {
        Custom_Alert("Not added",checkMsg,false);
        //alert(checkMsg);
      }
      
    });
  
    
  // Custom_Alert("WAPP CAMPAIGN","<span>Test1</span><span>Test2</span>")
    
   
  }
       
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getMasters();
}




      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
   
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card card-secondary">
              {/* <!-- /.card-header --> */}
           <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Student Bulk Import</b></h3>
                <a className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#divSessionYear" href="javascript:void(0)">Add New</a>
              </div>
              <form class="form-group" id="frmStudentBulkImport" name="frmStudentBulkImport" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">  
            <div className="card-body">
               <div className="row">
               <div className='col-lg-3 col-md-3 col-sm-4 float-right'>
<div className="form-group">
<label for="exampleInputEmail1">Branch</label>
         <div className="input-group">
               <select id="branch_id" name="branch_id" className="form-control required form-control-sm">
                {/* <option value={"0"}>-Branches-</option> */}
                {this.state.stListBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>
               <div className="col-lg-3 col-md-3">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div className="input-group input-group-sm">
                                            <select id="session_year_id" name="session_year_id" className="form-control required">
                                                  <option value="0">-Select Session Year-</option>
                                                  {this.state.stListSessionYear.map((item,index)=>{
                                                    return <option value={item.id}>{item.session_name}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Class</label>
                                            <div className="input-group input-group-sm">
                                            <select id="class_id" name="class_id" className="form-control required">
                                                  <option value="0">-Select Class-</option>
                                                  {this.state.stListClassMaster.map((item,index)=>{
                                                    return <option value={item.id}>{item.class_name}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>

               </div>
               <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">File upload [Select SessionYear, and Class] [Supported formate is xlsx/slx only],<a href={"../student_bulk_import.xlsx"}>download sample file</a></label>
                                            <div className="input-group input-group-sm123">
                                              <input type="file" id="file" name="file" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="col-md-6 respSSL"></div>
                                <div id="updResponsePro"></div>
                                <div class="row">
                                    <div class="col-6">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Update Now</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>                    
            </div>   
            </form>   
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Student_Bulk_Import;