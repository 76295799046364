import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,
  validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
//import {Comp_Add_Product_Master} from '../components/comp_add_product_master'
// import {Comp_Add_Party_Info} from '../components/comp_add_party_info';
import {Comp_Add_Employee} from '../components/comp_add_employee';
import {Comp_Add_Accessibility} from '../components/comp_add_accessibility';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsViewEmployee;

let rf="0";

let _objAccess={};
class View_Employee extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={stListBranches:[], listUsers:[],stEditUser:{},stListAccessibility:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewEmployee=this;
      }
      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getMastersRequest"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getMastersRequest";
        CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
         // alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               // alert(JSON.stringify(listData.receipt_amount));
               clsViewEmployee.setState({stListBranches:listData.ls_branches},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }

      getAssignAccessibility(item){
        let map=[];
        map.push({name:"curl_type",value:"get_master_access"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"req_user_id",value:btoa(item.id)});
        //map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="get_master_access";
        CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
          //alert(data);
          if(data!=="0" && data!==""){
            let lsData=JSON.parse(data);
            clsViewEmployee.setState({stListAccessibility:lsData["list_module"],stEditUser:item,

            });
            

          }
        })

      }

      getUserInfo(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"get_user_info"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="get_user_info";
        CallMapMethod_CBack(clsViewEmployee,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsViewEmployee.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsViewEmployee.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsViewEmployee.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }
      okRespCreateUser(data,msg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful",msg,false);
          //clsViewEmployee.getUserInfo();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",msg,false);
        }
        // alert(data);
      }


setEditUserInfo(item){
  // method will work on click on Credit Button Link to set State
  clsViewEmployee.setState({stEditUser:item},()=>{
    
  })

 }             
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getUserInfo();
this.getCategories();

$('#divAddEmployee').on('hidden.bs.modal', function () {
  //alert("i m in hide");
  //clsViewEmployee.setState({stFeeCategory:{"nn":"00"}});
  //clsViewEmployee.forceUpdateHandler();
  clsViewEmployee.getUserInfo();
 
});
$('#divAddAccessibility').on('hidden.bs.modal', function () {
  //alert("i m in hide");
  //clsViewEmployee.setState({stFeeCategory:{"nn":"00"}});
  //clsViewEmployee.forceUpdateHandler();
  clsViewEmployee.getUserInfo();
 
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan1">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View Employee</b></h3>
                <div className="row content12 mt11-2 mb11-2">


<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getUserInfo()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
<div class="col-md-6">
<a className="btn btn-sm btn-danger float-right" onClick={()=>this.setEditUserInfo({})} data-bs-toggle="modal" data-bs-target="#divAddEmployee" data-toggle="modal" data-target="#divAddEmployee" href="javascript:void(0)">Add New</a>
</div>
    </div>    
                
                
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Creation Date</th>
                      <th>Emp Id</th>
                      <th>Emp Name</th>
                      <th>Emp Contact No</th>
                      <th>UserName</th>
                      {/* <th></th> */}
                      <th>Status</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                  <td>{item["date"]}</td>      
                  <td>{item["account_no"]}</td>
                  <td>{item["contact_person"]}</td>
                  <td>{item["contact_no"]}</td>
                  <td>{item["user_name"]}</td>
                  {/* <td>{item["address"]}</td> */}
                  <td>{item.status}</td>
                  <td>{item.is_active}</td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {typeof _objAccess.can_edit!=="undefined"?<a class="dropdown-item" onClick={()=>this.setEditUserInfo(item)} data-bs-toggle="modal" data-bs-target="#divAddEmployee" data-toggle="modal" data-target="#divAddEmployee" href="javascript:void(0)">View/Edit</a>:''}
                    {typeof _objAccess.can_user_access!=="undefined"?<a class="dropdown-item" onClick={()=>this.getAssignAccessibility(item)} data-bs-toggle="modal" data-bs-target="#divAddAccessibility" data-toggle="modal"  data-target="#divAddAccessibility" href="javascript:void(0)">Accessibility</a>:''}
                    </div>
                  </div>
    </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Add_Accessibility listBranches={this.state.stListBranches} editUser={this.state.stEditUser} listAccessibility={this.state.stListAccessibility} logUser={userObject} />
<Comp_Add_Employee editUser={this.state.stEditUser} logUser={userObject}/>

    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default View_Employee;