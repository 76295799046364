import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getCol3} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Student_Tc_Rel from '../components/comp_student_tc_rel';






let loginUser="";
let userObject="";
let companyName="";
let clsStudentAdmission;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
class Student_Tc_Proc extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

  exportPdf() {
    var whe = window.innerHeight;
    var wwid = window.innerWidth;

    let data = document.getElementById("exp_body").innerHTML;
    /*
    alert(data);
    var doc = new DOMParser().parseFromString(data, "text/xml");

    alert(doc.getElementsByTagName("<div>")[1]);
    */
    var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
    printWindow.document.write('<html><head><title>Export Data</title>');
    //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
    //printWindow.document.write('<link rel="stylesheet" href="~/dist/css/adminlte.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
    printWindow.document.write('<style>table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(data);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    //alert(printWindow.document.getElementById("exp_body").innerHTML);
    //printWindow.document.getElementById("exp_body").removeAttribute("style");
    //delete cell from table
    let tbList = printWindow.document.getElementById("tbData");
    let tbRow = tbList.rows;
    for (let i = 0; i < tbRow[0].cells.length; i++) {
        var str = tbRow[0].cells[i].innerHTML;
        if (str.search("Payment Status") != -1) {
            for (var j = 0; j < tbRow.length; j++) {

                // Deleting the ith cell of each row
                tbRow[j].deleteCell(i);
            }
        }
    }

    setTimeout(function () {
        printWindow.print();
    }, 500);
    return false;
}

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={stListBranches:[] ,stListTransactions:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stTcAdd:{}};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsStudentAdmission=this;
      }





      getStudentFeeTransaction(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getStudentTerminationView"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        map.push({name:"ser_branches",value:$("#ser_branches").val()});
        // map.push({name:"ser_type",value:$("#ser_type").val()});
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getStudentTerminationView";
        CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                let vTotalPayable=0;
                let vPaid=0;
                let vOtherDues=0;
                for(let i=0;i<listData.length;i++){
                    // alert(listData[i]["paid_fee"]);
                    // break;
                  if(listData[i]["paid_fee"]!=="" && listData[i]["paid_fee"]!==null){
                    vPaid+=parseFloat(listData[i]["paid_fee"]);
                  }
                  if(listData[i]["other_due"]!=="" && listData[i]["other_due"]!==null){
                    vOtherDues+=parseFloat(listData[i]["other_due"]);
                  }
                  vTotalPayable+=parseFloat(listData[i]["net_amount"]);
                }
                let strYear="";
                if($("#ser_session_year").val()!=="0" || $("#ser_session_year").val()!=="0"){
                  strYear="| Session-Year:<b>"+ $('#ser_session_year option:selected').text()+"</b>";
                }
                let strClass="";
                if($("#ser_class_name").val()!=="0" || $("#ser_class_name").val()!=="0"){
                  strClass="| Class:<b>"+ $('#ser_class_name option:selected').text()+"</b>";
                }
                $(".outstanding_rpt").html(listData.length+" records found | Total Fee:<b>"+vTotalPayable+"</b> | Submitted Fee:<b>"+vPaid+"</b> | Other Pending Due:<b>"+vOtherDues+"</b>| Total Dues:<b>"+((vTotalPayable-vPaid)+vOtherDues)+"</b>"+strYear+strClass);
                
                clsStudentAdmission.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsStudentAdmission.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  
onTcProcAdd(item)
{
   // alert(JSON.stringify(item));
 clsStudentAdmission.setState({stTcAdd:item});
}

onSubmitTermination(frmName){
    btnSetColor_CBack(this,frmName,"submitStuTermination",function(data,msg){
        if(data==="001"){
            alert("Submitted Successfully");
            //clsStudentAdmission.getStudentAdmission();
        }
    })
}

getMasters(){
  var map=[];
  map.push({name:"curl_type",value:"getMastersRequest"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"branch_acc",value:getCol3()});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getMastersRequest";
  CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          //alert(JSON.stringify(listData.master_balance));
          clsStudentAdmission.setState({
            stListSessionYear:listData.ls_session_year,
            stListClassMaster:listData.ls_class_master,
          stListFeeCategory:listData.ls_fee_category,
          stListBranches:listData.ls_branches},()=>{
            clsStudentAdmission.forceUpdateHandler();
          })
        }
  });
}
          
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
//this.getStudentFeeTransaction();
this.getMasters();

$('#divStudentTCRel').on('hidden.bs.modal', function () {
    //window.location.reload();
    // clsStudentAdmission.setState({stLsStudentBasic:[],
    //   stLsStudentFamily:[],stLsStudentAuth:[],
    //   stStudentId:"0"},()=>{
    //     clsStudentAdmission.forceUpdateHandler();
    //   });
    //$("#frmEditStudentAuth")[0].reset();
    clsStudentAdmission.getStudentFeeTransaction();
  });

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
{/* for now [row]it is unused */}
{/* starting of cols */}

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_session_year" name="ser_session_year" className="form-control form-control-sm">
                <option value={"0"}>-Session Year-</option>
                {this.state.stListSessionYear.map((item,index)=>{
                  return <option value={item["id"]}>{item["session_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_branches" name="ser_branches" className="form-control form-control-sm">
               {this.state.stListBranches.length>1?<option value={btoa("0")}>-All-</option>:''}
                {this.state.stListBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_class_name" name="ser_class_name" className="form-control form-control-sm">
                <option value={"0"}>-Classes-</option>
                {this.state.stListClassMaster.map((item,index)=>{
                  return <option value={item["id"]}>{item["class_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               {/* <select id="ser_type" name="ser_type" className="form-control form-control-sm">
                <option value={"0"}>-Type-</option>
                <option value={"Credit"}>-Credit-</option>
                <option value={"Debit"}>-Debit-</option>
               </select> */}
             </div>
         
         </div>
</div>

<div className='col-lg-5 col-md-5 col-sm-5 float-right'>
<div className="form-group">
         <div className="input-group">
         {/* <TableDatePicker refName="ser_from_date" />-To-
         <TableDatePicker refName="ser_to_date"/> */}
             </div>
         
         </div>
</div>

{/* 
<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div> */}

<div className="col-lg-12 col-md-12 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               {/* <div className='col-1211 '> */}
                 <div onClick={()=>this.getStudentFeeTransaction()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()} className="btn btn-sm btn-danger">Reset</div>
                 <div onClick={()=>this.exportPdf()} className="btn btn-sm btn-primary float-right">Export</div>

                 {/* </div> */}
               </div>
             </div>
</div>
{/* end of cols */}

    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card" id="exp_body">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Student Master</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Scholar No</th>
                      <th>Student Name</th>
                      <th>Father's Name</th>
                      <th>Session Year</th>
                      <th>Class Name</th>
                      <th>Total Amount</th>
                      <th>Paid Amount</th>
                      <th>Pending Due</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                    let vTotalAmount=parseFloat(item.net_amount);
                    

                    let vPaidAmount=0;
                    if(item.paid_fee!==null && item.paid_fee!==""){
                      vPaidAmount=parseFloat(item.paid_fee);
                    }
                    let vNetDues=0;
                    if(item.other_due!==null && item.other_due!==""){
                        let vOtherDues=parseFloat(item.other_due);
                        vTotalAmount+=vOtherDues;
                        
                    }
                    vNetDues= vTotalAmount-vPaidAmount;
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item.scholar_no}</td>
                        <td>{item.student_name}</td>
                        <td>{item.father_name}</td>
                        <td>{item.session_name}</td>  
                        <td>{item.class_name}</td>  
                        <td>Rs.{item.net_amount}</td>
                    <td>Rs.{vPaidAmount}</td>    
                    <td>Rs.{vNetDues}</td>
                    <td><b>{item.is_active}</b></td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {item["is_active"]!=="Terminated"?<a class="dropdown-item" onClick={()=>this.onTcProcAdd(item)} data-bs-toggle="modal" data-bs-targer="#divStudentTCRel" data-toggle="modal" data-target="#divStudentTCRel" href="javascript:void(0)">Process TC</a>:''}
                   </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
   <Comp_Student_Tc_Rel studentForTC={this.state.stTcAdd} 
   lsSessionYear={this.state.stListSessionYear}
   lsClassMaster={this.state.stListClassMaster}
   submitTermination={this.onSubmitTermination} />
    {/* <Comp_Class_Fee_Master lsSessionYear={this.state.stListSessionYear} lsClassMaster={this.state.stListClassMaster} lsFeeCategory={this.state.stListFeeCategory} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Student_Tc_Proc;