export function getUserObjData(){
  var cObject="";
  if(localStorage.getItem("userData")!=null){
    var tmpObject=JSON.parse("{\"access\":"+localStorage.getItem("userData")+"}");
    cObject=tmpObject.access['user'];
  }
  return cObject;
}
export function getAccessRoleNew(){
  let cObject=[];
  if(localStorage.getItem("userData")!=null){
    let tmpObject=JSON.parse(localStorage.getItem("userData"));
    cObject=tmpObject.role;
  }
  return cObject;
}
export function getCol3(){
  let vCol3=btoa("-1");
  //alert(localStorage.getItem("userData"));
  if(localStorage.getItem("userData")!==null){
    let tmpObject=JSON.parse(localStorage.getItem("userData"));
    if(typeof tmpObject.col3 !=="undefined"){
      vCol3=tmpObject.col3;
    }
    
  }
  return vCol3;
}

export function getAccessFunctions(){
  let accessObj={}
  if(localStorage.getItem("userData")!=null){
    let tmpObject=JSON.parse(localStorage.getItem("userData"));
    let cObject=tmpObject.role;
   
    let access=cObject[0]["col1"];
    //alert(access);
    if(access.trim()!==""){
  
      let arrAccess=atob(access).split(',');
      for(let a=0;a<arrAccess.length;a++){
        if(arrAccess[a]==="edit"){
          accessObj["can_edit"]=true;
        }
        if(arrAccess[a]==="delete"){
          accessObj["can_delete"]=true;
        }
        if(arrAccess[a]==="accessibility"){
          accessObj["can_user_access"]=true;
        }
        if(arrAccess[a]==="access_log"){
          accessObj["access_log"]=true;
        }
      }
    }
    
  }
  return accessObj;
}

var UserProfile = (function() {
    let userObject="";
    let accessRoles="";  
    var getUserObject = function() {
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['user'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };

    var getAccessRoles=function(){
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['role'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };
  
    var setUserObject = function(userObj) {
        userObject = userObj;     
      // Also set this in cookie/localStorage
    };
  
    return {
        getUserObject: getUserObject,
        setUserObject: setUserObject,
        getAccessRoles:getAccessRoles
       
    }
  
  })();
  
  export default UserProfile;