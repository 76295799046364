import $ from 'jquery';
import React from 'react';

import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {setProfilePic,defaultClick,getAccessRolesNew,CallMapMethod,
  CallMapMethod_CBack,_getInitParam,defaultLogo,setTabIndex,sortList} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import UserDefault from '../assets/images/9.png';

import {Comp_Link_Acccess} from '../components/comp_link_access';


import './header.css';
let listRoles;
let loginUser;
let userObject;
let companyName="";
let clsHeader;

let _initObjectLib;

let LogoImg;

class Header extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="../";
  }

  

  constructor(props){
  super(props);
  clsHeader=this;
    listRoles=[];
    LogoImg=defaultLogo();
    this.state={stAccessList:[]}
    if(localStorage.getItem("userData")!=null){
      userObject= getUserObjData();
      //alert(JSON.stringify(userObject));
      loginUser=userObject[0].first_name;
      if(typeof userObject[0].company_name!=="undefined"){
        companyName=userObject[0].company_name;
      }
      _initObjectLib=_getInitParam();
      //alert(LogoImg);
  }
  let ret=getAccessFunctions();
 if(userObject[0].type==="1" || userObject[0].type==="2" || userObject[0].type==="3"){

    listRoles.push({"id":"1","name":"Dashboard","parent_id":"0","red_link":"../dashboard","description":"","display_option":"menu"});
    if(userObject[0].type==="1" && ret==="user,master"){
      
      if(_initObjectLib.app_settings.settings.menu_category["menu_type"]==="all" || _initObjectLib.app_settings.settings.menu_category["menu_type"]==="e-com")
      {
        
        listRoles.push({"id":"5","name":"Admin","parent_id":"0","red_link":"../dashboard","description":"","display_option":"menu"});
        
        listRoles.push({"id":"56","name":"Employee","parent_id":"5","red_link":"../view_employee","description":"","display_option":"menu"});

        listRoles.push({"id":"51","name":"Session Year","parent_id":"5","red_link":"../session_year","description":"","display_option":"menu"});
        listRoles.push({"id":"52","name":"Fee Categories","parent_id":"5","red_link":"../fee_categories","description":"","display_option":"menu"});
        listRoles.push({"id":"53","name":"Class Master","parent_id":"5","red_link":"../class_master","description":"","display_option":"menu"});
        listRoles.push({"id":"54","name":"Class Fee Mgmt","parent_id":"5","red_link":"../class_fee_master","description":"","display_option":"menu"});
        listRoles.push({"id":"55","name":"Transportation Master","parent_id":"5","red_link":"../transport_master","description":"","display_option":"menu"});
        //listRoles.push({"id":"81","name":"Team Users","parent_id":"5","red_link":"../dashboard","description":"","display_option":"menu"});
        //listRoles.push({"id":"82","name":"Web Users","parent_id":"5","red_link":"../thrashold_limit","description":"","display_option":"menu"});
    
        //listRoles.push({"id":"52","name":"Slot Size","parent_id":"5","red_link":"../slot_size","description":"","display_option":"menu"});
        //listRoles.push({"id":"52","name":"User Settings","parent_id":"5","red_link":"../user_settings","description":"","display_option":"menu"});
        //listRoles.push({"id":"53","name":"Queue Monitor","parent_id":"5","red_link":"../queue_monitor","description":"","display_option":"menu"});
        //listRoles.push({"id":"54","name":"Push Messages","parent_id":"5","red_link":"../push_messages","description":"","display_option":"menu"});
        
        //listRoles.push({"id":"7","name":"Registered Users","parent_id":"5","red_link":"../user_mgmt","description":"","display_option":"menu"});

      }
    
    }
    else if(userObject[0].type==="1" && ret==="user"){
      // access menu for use
      if(_initObjectLib.app_settings.settings.menu_category["menu_type"]==="all" || _initObjectLib.app_settings.settings.menu_category["menu_type"]==="e-com")
      {
        listRoles.push({"id":"7","name":"Web Users","parent_id":"0","red_link":"../user_mgmt","description":"","display_option":"menu"});
      }
      
    }
    if(_initObjectLib.app_settings.settings.menu_category["menu_type"]==="all" || _initObjectLib.app_settings.settings.menu_category["menu_type"]==="e-com")
    {
      listRoles.push({"id":"2","name":"Student Management","parent_id":"0","red_link":"../patient_entry","description":"","display_option":"menu"});
      listRoles.push({"id":"21","name":"Student Admission","parent_id":"2","red_link":"../student_admission","description":"","display_option":"menu"});
      listRoles.push({"id":"21","name":"Student Bulk Import","parent_id":"2","red_link":"../student_bulk_import","description":"","display_option":"menu"});
      listRoles.push({"id":"21","name":"View Students","parent_id":"2","red_link":"../view_student_admission","description":"","display_option":"menu"});
      listRoles.push({"id":"21","name":"Student Transport.","parent_id":"2","red_link":"../view_student_transport","description":"","display_option":"menu"});


      listRoles.push({"id":"21","name":"Promot Students","parent_id":"2","red_link":"../promot_student","description":"","display_option":"menu"});
      
      listRoles.push({"id":"21","name":"Students Termination","parent_id":"2","red_link":"../student_tc_proc","description":"","display_option":"menu"});
      listRoles.push({"id":"21","name":"Terminated List","parent_id":"2","red_link":"../student_tc_list","description":"","display_option":"menu"});

      listRoles.push({"id":"3","name":"Fee Payments","parent_id":"0","red_link":"../#","description":"","display_option":"menu"});
      listRoles.push({"id":"31","name":"Student Fee Allot.","parent_id":"3","red_link":"../student_fee_allotment","description":"","display_option":"menu"});
      listRoles.push({"id":"32","name":"Submit Fee","parent_id":"3","red_link":"../submit_fee","description":"","display_option":"menu"});
      listRoles.push({"id":"33","name":"View Fee Transactions","parent_id":"3","red_link":"../view_fee_transactions","description":"","display_option":"menu"});
      listRoles.push({"id":"34","name":"Fee Breakups Trans.","parent_id":"3","red_link":"../view_fee_breakups_trans","description":"","display_option":"menu"});

      listRoles.push({"id":"4","name":"Reports","parent_id":"0","red_link":"../#","description":"","display_option":"menu"});
      //listRoles.push({"id":"4","name":"View Class Wise","parent_id":"4","red_link":"../#","description":"","display_option":"menu"});
      //listRoles.push({"id":"4","name":"View Session Year Wise","parent_id":"4","red_link":"../#","description":"","display_option":"menu"});
      listRoles.push({"id":"4","name":"View Student Wise Fee","parent_id":"4","red_link":"../view_student_wise_due","description":"","display_option":"menu"});
      //listRoles.push({"id":"4","name":"View Class Wise Fee","parent_id":"4","red_link":"../view_class_wise_due","description":"","display_option":"menu"});
      

      //listRoles.push({"id":"4","name":"Reports","parent_id":"0","red_link":"../opd_reports","description":"","display_option":"menu"});

      //listRoles.push({"id":"6","name":"IPD Reports","parent_id":"0","red_link":"../ipd_reports","description":"","display_option":"menu"});
      //listRoles.push({"id":"7","name":"Department","parent_id":"0","red_link":"../deparment","description":"","display_option":"menu"});
  
      //listRoles.push({"id":"8","name":"Diagnosis","parent_id":"0","red_link":"../diagnosis","description":"","display_option":"menu"});
      //listRoles.push({"id":"9","name":"Doctors","parent_id":"0","red_link":"../doctors","description":"","display_option":"menu"});
     
    }
  }
   
    if(localStorage.getItem("userDS")!=null){
      userObject= JSON.parse(localStorage.getItem("userDS"));
      //alert(JSON.stringify(userObject));
      loginUser=userObject[0].first_name;
      if(typeof userObject[0].company_name!=="undefined"){
        companyName=userObject[0].company_name;
      }
  }
  }
  callBackNg(data,msg){
   var check=JSON.parse(data);
   if(check.length==0){
    // alert("Invalid User !Kindly contact to Admin")
    localStorage.clear();
    window.location.href="../";
   }
  }

  
  getAssignAccessibility(){
    //alert("i m in call");
    let map=[];
    map.push({name:"curl_type",value:"get_master_access"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"local_token",value:userObject[0].app_user_id});
    map.push({name:"user_type",value:userObject[0].type});
    map.push({name:"enc_req_user_id",value:userObject[0].id});
    //map.push({name:"ser_userType",value:$("#ser_userType").val()});
    map.push({name:"ser_text",value:$("#ser_text").val()});
    map.push({name:"upper_id",value:userObject[0].upper_id});
    
    map["res_func"]="callBackrrr";
    map["curl_type"]="get_master_access";
    CallMapMethod_CBack(clsHeader,map,function(data,msg){
      //alert(data);
      if(data!=="0" && data!==""){
        let lsData=JSON.parse(data);
        let lsMainList=lsData["list_module"];
        let lsUser=lsData["list_user"];
        let lsAssignList=[];
       
        if(lsUser.length>0){
          let strUserLoc=lsUser[0]["access_location"];
          if(strUserLoc.trim()===""){
            alert("Don't have accessibility ,Kindly contact to admin");
            return;
          }else{
            let arrUserLoc=strUserLoc.split(',');
            for(let a=0;a<arrUserLoc.length;a++){
              const listFltr = d => d.id === parseInt(arrUserLoc[a]);
              //alert(arrUserLoc[a]);
              let tmpList=lsMainList.filter(listFltr);
              for(let t=0;t<tmpList.length;t++){
                lsAssignList.push(tmpList[t])
              }
            }
          }
          lsAssignList=sortList(lsAssignList,"priority",true,true)
          clsHeader.setState({stAccessList:lsAssignList},()=>{
            setTabIndex('.navbar .navbar-brand',-1);
    setTabIndex('.navbar-nav .nav-item .nav-link',-1);
          });
          //alert(JSON.stringify(lsAssignList));
        }else{
          window.location.href="../";
        }
        //stAccessList
        //clsHeader.setState({stListAccessibility:lsData["list_module"],stEditUser:item});
        

      }
    })

  }
  
  componentDidMount(){

    
    if(listRoles.length!=0){
      //alert("i am in ");
      var listHtm=getAccessRolesNew(listRoles);
     
     //$("#menu_opt").append(listHtm);
    }
    this.getAssignAccessibility();
    setTabIndex('.modal-header .close',-1);
    
  }
 
    render(){
        return(
          <div>
          <nav class="navbar navbar-expand-lg my-bg-color-1 my-shadow">
          {/* <a class="navbar-brand text-white ml-3" href="#"><i class="fa fa-user-circle"></i> {loginUser}  </a> */}
          <a href="/dashboard" className="navbar-brand"> <img style={{/*"background-color":"#000"*/}} width="30" src={_initObjectLib["logoPath"].trim()!==""?_initObjectLib["logoPath"]:LogoImg} alt="logo" className="brand-image elevation-3 img-thumbnail" /> <span className="brand-text white" >{loginUser}</span> </a>
          <ul class="navbar-nav">
              
          </ul>
        
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"><i class="fa ffg fa-bars "></i></span>
          </button>
        
          <div class="collapse navbar-collapse" id="nav">
          
        
              <ul id="menu_opt" class="navbar-nav bg-light-my m-0 ml-lg-auto mr-lg-auto p-3 p-lg-0 m-auto1">
              
              <li class="d-inline d-lg-none">
                <button data-toggle="collapse" data-target="#nav" class="close float-right">&times;</button>
            </li>
            <li class="nav-item d-lg-none d-md-none d-sm-none  ">
              {/* <img src={UserDefault} width="80" class="img-circle img-thumbnail img-fluid m-auto text-cente" /> */}
              <img src={_initObjectLib["logoPath"].trim()!==""?_initObjectLib["logoPath"]:LogoImg} hight="50px" class="img-circle img-thumbnail img-fluid m-auto text-cente" />
              

				<h6 class="mt-4">Welcome {loginUser} </h6>
				<div class="border-bottom w-100 mt-1 mb-1"></div>
			
			</li>
      <Comp_Link_Acccess roles={this.state.stAccessList}/>
              </ul>
                {/* <!-- Right navbar links --> */}
            <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* <select className="form-control">
                 <option>Year</option>
                 <option>2021</option>
            </select> */}
              {/* <!-- Messages Dropdown Menu --> */}
              <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i style={{fontSize:"2em"}} class="fa fa-bell white"></i>
          <span class="badge badge-warning navbar-badge" id="count_notification"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="../recent_notification" class="dropdown-item">
            <i class="fa fa-envelope mr-2"></i>Recent Compliance
            <span class="badge badge-warning float-right text-muted text-sm" id="compliance_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fa fa-users mr-2"></i>Recent Notices
            <span class="float-right text-muted text-sm" id="notices_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fa fa-file mr-2"></i>ACTA Updates
            <span class="float-right text-muted text-sm" id="acta_count"></span>
          </a>
          <div class="dropdown-divider"></div>
          {/* <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> */}
        </div>
      </li>
              <li className="nav-item dropdown12"> <a className="nav-link" data-toggle="dropdown" href="#"> <i style={{fontSize:"2em"}} className="fa fa-user white fa-2x"></i> </a>
                <div id="dropdown12" className="dropdown-menu dropdown-menu-lg dropdown-menu-right"> 
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item"> <i className="fa fa-book mr-2"></i> <b>Entity:</b> {companyName}</a>
                  <a href="#" className="dropdown-item"> <i className="fa fa-user mr-2"></i> Welcome {loginUser}</a>
                  <div className="dropdown-divider"></div>
                  
                  <a href="javascript:void(0)" onClick={()=>this.logout()} className="dropdown-item"> <i className="fa fa-sign-out mr-2"></i> Logout </a>
                  <div className="dropdown-divider"></div>
                  </div>
              </li>
            </ul>
          </div>
      </nav>


      </div>
          )
    }
}
export default Header