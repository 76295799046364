import $, { extend } from 'jquery';
import React,{useState} from 'react';
import {UserToken,getUserObjData,getCol3} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters,
  CallMapMethod_CBack,btnSetColor_CBack} from '../function_lib/common_lib';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';



import defaultPic from '../assets/images/9.png';
import pdfIcon from '../assets/images/pdf_icon.png';
import mp3Icon from '../assets/images/mp3_icon.png';
import mp4Icon from '../assets/images/mp4_icon.png';

let loginUser="";
let userObject="";
let companyName="";
let clsWappCamp;


let totalFileAllow=5;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];

//let genderList=["पु.","म.","बा.","बालि."];
let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];
class Student_Admission extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();

          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={stDates:{admit_date:"",dob:""},stListBranches:[],stListSessionYear:[],stListClassMaster:[],stListFeeCategory:[]};
      clsWappCamp=this;
    
      }

      handleDateChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value.replace(/\D/g, "");
      
        if (formattedValue.length >= 2) formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2);
        if (formattedValue.length >= 5) formattedValue = formattedValue.slice(0, 5) + '/' + formattedValue.slice(5);
        clsWappCamp.setState({
          ...clsWappCamp.stDates,
          [name]:formattedValue});
        // setDates({
        //   ...dates,
        //   [name]: formattedValue
        // });
      };
    
      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getMastersRequest"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        map.push({name:"branch_acc",value:getCol3()});
        //alert(atob(getCol3()));

        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getMastersRequest";
        CallMapMethod_CBack(clsWappCamp,map,function(data,msg){
         // alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
               // alert(JSON.stringify(listData.receipt_amount));
                clsWappCamp.setState({stListSessionYear:listData.ls_session_year,
                  stListClassMaster:listData.ls_class_master,
                stListFeeCategory:listData.ls_fee_category,
                stListBranches:listData.ls_branches},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }

//  check file validation
      fileValidation(){
        let allowFile=true;
     let imgCount=objImages.length;
     let vidCount=objVideo.length;
     let pdfCount=objPdf.length;

     let vTotalCount=imgCount+vidCount+pdfCount;
    
     if(vTotalCount>=totalFileAllow){
      alert("Only "+totalFileAllow+" to be allowed .");
      allowFile=false;
     }
     return allowFile;
 }
 //  check file validation     
  rmPdfProc(data){
    
        if(document.getElementById("ref_pdf_"+data)!=null){
          document.getElementById("ref_pdf_"+data).remove();
        }
        
        this.lcRemoveJSON(data,"pdf");
      }    

PdfThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(flImg==="-10"){
    alert(flName);
    return;
  }
   if(othInfo===""){
    othInfo="nm"+pdfIndexExisting+","+pdfIndexExisting;
  }
  pdfIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrPdfExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_pdf_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+pdfIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+pdfIcon+" /></a>" +
    "</div>";
  
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objPdf.push({ name: arrNmValue[1], value: flImg });
  $("#lsPdf").val("{\"objs\":" + JSON.stringify(objPdf) + "}");
  //alert(strSet);            
  $(".pdfLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappCamp, "_removeBtn"+pdfIndexExisting, "rmPdfProc", "2");
  //imgLibThumb
}      
rmVideoProc(data){
  if(document.getElementById("ref_vid_"+data)!=null){
    document.getElementById("ref_vid_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"video");
}
VideoThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+vidIndexExisting+","+vidIndexExisting;
  }
  vidIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrVidExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+vidIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + mp3Icon + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
     strSet= "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+vidIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+mp4Icon+" /></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objVideo.push({ name: arrNmValue[1], value: flImg });
  $("#lsVideo").val("{\"objs\":" + JSON.stringify(objVideo) + "}");
  //alert(strSet);            
  $(".vidLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappCamp, "_removeBtn"+vidIndexExisting, "rmVideoProc", "2");
  //imgLibThumb
}      
rmFileProc(data){
  
    if(document.getElementById("ref_div_"+data)!=null){
      document.getElementById("ref_div_"+data).remove();
    }
    
    this.lcRemoveJSON(data,"image");
}
/* Loc */
lcRemoveJSON(refKey,fType){
if(fType==="image"){
  var jsonP=JSON.parse($("#lsImages").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsImages").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objImages=arrJson;
  
}else if(fType==="video"){
  var jsonP=JSON.parse($("#lsVideo").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsVideo").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objVideo=arrJson;
}else if(fType==="pdf"){
  var jsonP=JSON.parse($("#lsPdf").val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#lsPdf").val(JSON.stringify(jsonP));
      
      break;
    }
  }
  objPdf=arrJson;
}


 
}     
ImageThumbBack(flImg, flName,fObject,othInfo="") {
  
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: flImg });
 // alert(objImages.length);
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
  //alert(strSet);            
  $(".imgLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsWappCamp, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
      }


saveWappCompaign(){
  //alert("Sending WAPP");
  //alert($("#contact_no").val());
/*
  let checkChar= $("#msg_text").val();
   let result=detectEmojisAndSpecialCharacters(checkChar);
  
if(result.emojis.length!==0 || result.specialCharacters.length!==0){
   alert("Special Characters and Emojis not allowd in text Message");
   return; 
}
*/


//btnSetColor_CBack(clsWappCamp,frmWappCamp,"createPt",function(data,msg))
btnSetColor_CBack(clsWappCamp,"frmWappCamp","studetnAdmission",function(checkData,checkMsg){
    //alert(checkData+checkMsg)
    if(checkData===-10){
      alert("Kindly fill required fields");
      return;
    }else if(checkData==="001"){
      Custom_Alert("Successfully Added",checkMsg,true);
        //window.location.reload();
    }else if(checkData==="01") {
      Custom_Alert("Not added",checkMsg,false);
      //alert(checkMsg);
    }
    
  });

  
// Custom_Alert("WAPP CAMPAIGN","<span>Test1</span><span>Test2</span>")
  
 
}

 isFloat(n) {
  let checkNum = false;
  if (Number(n)) {
      checkNum = true;
  } else if (n % 1 !== 0) {
      checkNum = true;
  } else {
      checkNum = false;
  }

  return checkNum;
}

 
componentDidMount(){
    
  this.getCategories();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);


$(".cd_input_change").change(function () {
  let objPreCost = document.getElementById("pre_cost");
  let objOfferPer = document.getElementById("offer_per");
  let objFCost = document.getElementById("txt_cost");

    let valPreCost = 0;
    let valOfferPer = 0;
    let valFCost = 0;

    if (clsWappCamp.isFloat(objPreCost.value.trim())) {
      valPreCost = objPreCost.value.trim();
    }
    if (clsWappCamp.isFloat(objOfferPer.value.trim())) {
      valOfferPer = objOfferPer.value.trim();
    }
    if (clsWappCamp.isFloat(objFCost.value.trim())) {
      valFCost = objFCost.value.trim();
    }

    if(valOfferPer!==0){
      objFCost.value = valPreCost-(valPreCost * valOfferPer / 100).toFixed(2);
    }else{
      objFCost.value=objPreCost.value;
    }



})
}

   render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-12 col-lg-12"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-primary">
<div class="card-header">
                                <h3 class="card-title">Admission Form</h3>
                                <a className="btn btn-sm btn-danger float-right" href="../view_student_admission">View Student</a>
                            </div>
                            <form class="form-group" id="frmWappCamp" name="frmWappCamp" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="studetnAdmission" />
                            <input type="hidden" name="m_type" id="m_type" value="studetnAdmission" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                          
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsVideo" id="lsVideo"/>
                            <input type="hidden" name="lsPdf" id="lsPdf"/>
                            <div class="card-body">
                                <div className="row">
                                <div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
<label for="exampleInputEmail1">Branch</label>
         <div className="input-group">
               <select id="branch_id" name="branch_id" className="form-control required form-control-sm">
                {/* <option value={"0"}>-Branches-</option> */}
                {this.state.stListBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>

                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Admission Date(DD/MM/YYYY)</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="admit_date" onChange={()=>this.handleDateChange} name="admit_date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Form No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="form_no" name="form_no" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Scholar No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="scholar_no" name="scholar_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Admission Type *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="admission_type" name="admission_type" className="form-control required">
                                                  <option value="0">-Select Reg./RTE-</option>
                                                  <option value="Regular">Regular Admission</option>
                                                  <option value="RTE">RTE Admission</option>
                                                  {/* {this.state.stReceiptAmount.map((item,index)=>{
                                                    return <option value={item["id"]+","+item["amount"]}>{item["name"]}</option>
                                                  })} */}
                                                </select>
                                            </div>
                                        </div>
                                  </div>

                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">S.No</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="sno" name="sno" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}

                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OPD No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="opd_no" name="opd_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}
                                
                                </div>
                                <div class="row">
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Student Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="student_name" name="student_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Gender</label>
                                            <div className="input-group input-group-sm">
                                            <select id="gender" name="gender" className="form-control required">
                                                  <option value="0">-Select Category-</option>
                                                  {genderList.map((item,index)=>{
                                                    return <option value={item}>{item}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div> 
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">DOB</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="dob" name="dob" onChange={()=>this.handleDateChange} data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Category</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control" id="stu_cast_category" name="stu_cast_category">
                                                  <option value={"0"}>-Select-</option>
                                                  <option value={"GEN"}>GEN</option>
                                                  <option value={"OBC"}>OBC</option>
                                                  <option value={"SC"}>SC</option>
                                                  <option value={"ST"}>ST</option>
                                                </select>

                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Cast</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_cast" name="stu_cast" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                 
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div className="input-group input-group-sm">
                                             <select id="session_year_id" name="session_year_id" className="form-control form-control-sm required">
                                               <option value={"0"}>-Session Year-</option>
                                               {this.state.stListSessionYear.map((item,index)=>{
                                                return <option value={item["id"]}>{item["session_name"]}</option>
                                               })}
                                             </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Class</label>
                                            <div className="input-group input-group-sm">
                                            <select id="class_id" name="class_id" className="form-control form-control-sm required">
                                               <option value={"0"}>-Classes-</option>
                                               {this.state.stListClassMaster.map((item,index)=>{
                                                return <option value={item["id"]}>{item["class_name"]}</option>
                                               })}
                                             </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Section</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="section" name="section" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Shift</label>
                                            <div className="input-group input-group-sm">
                                                  <select id="shift" name="shift" className="form-control form-control-sm">
                                                  <option value={"0"}>-Select-</option>
                                                <option value={"First"}>-First-</option>
                                                <option value={"Second"}>-Second-</option>
                                                  </select>
                                                
                                            </div>
                                        </div>
                                  </div>
         
                                </div>  
                        <div className="row">
                        <div className="col-lg-3 col-md-3">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">WhatsApp No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="whats_no" name="whats_no" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">Alt. Contact No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="alt_contact_no" name="alt_contact_no" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                      </div>
                      <div className="row">    
                          <div className="col-lg-3 col-md-3">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">Place of Birth</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="place_of_birth" name="place_of_birth" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                          <div className="col-lg-2 col-md-2">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">Total Siblings</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="total_siblings" name="total_siblings" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">No of Sibling Study in Same School</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="no_of_sibling" name="no_of_sibling" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                        </div>        
                            {/* family members  */}
                            
        <div class="card card-default">
          <div class="card-header">
            <h3 class="card-title"><b>Parent's Detail</b></h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fa fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fa fa-times"></i></button>
            </div>
          </div>
         
          <div class="card-body">
            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Father's Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="father_name" name="father_name" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Father's Occupation</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="father_occup" name="father_occup" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Job Type</label>
                                            <div className="input-group input-group-sm">
                                                <select id="father_job_type" name="father_job_type" className="form-control">
                                                    <option value={"0"}>-Select Job Type-</option>
                                                    <option value={"Private"}>-Private Service-</option>
                                                    <option value={"Government"}>-Government Service-</option>
                                                </select>
                                            </div>
                                        </div>
                          </div> 
             
            </div>

            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Mother's Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="mother_name" name="mother_name" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Mother's Occupation</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="mother_occup" name="mother_occup" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Job Type</label>
                                            <div className="input-group input-group-sm">
                                                <select id="mother_job_type" name="mother_job_type" className="form-control">
                                                    <option value={"0"}>-Select Job Type-</option>
                                                    <option value={"Private"}>-Private Service-</option>
                                                    <option value={"Government"}>-Government Service-</option>
                                                    <option value={"House Wife"}>-House Wife-</option>
                                                </select>
                                            </div>
                                        </div>
                          </div> 
             
            </div>
            {/* address */}
            <div class="row">
            <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Residential Address</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="resi_address" name="resi_address" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Permanent Address</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="perm_address" name="perm_address" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          
             
            </div>
            {/* address */}
            
          </div>

        </div>
        
                            {/* end of family members */}
                            

  {/* other details  */}
                            
  <div class="card card-default">
          <div class="card-header">
            <h3 class="card-title"><b>Authorization Detail</b></h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
            </div>
          </div>
         
          <div class="card-body">
            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">SSSMID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_ssmid" name="stu_ssmid" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Family ID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_family_id" name="stu_family_id" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Aadhaar No</label>
                                            <div className="input-group input-group-sm">
                                              <input type="text" id="stu_aadhaar_no" name="stu_aadhaar_no" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>

            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="bank_name" name="bank_name" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Account No</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_bank" name="stu_bank" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Branch</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_bank_branch" name="stu_bank_branch" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">IFSC Code</label>
                                            <div className="input-group input-group-sm">
                                               <input type="text" id="stu_ifsc_code" name="stu_ifsc_code" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>
            {/* address */}
            <div class="row">
            <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Previous School Attend if Any</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="pre_school_name" name="pre_school_name" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Last Examination Passed Class</label>
                                            <div className="input-group input-group-sm">
                                              <select id="last_exam_class_id" name="last_exam_class_id" className="from-control form-control-sm required11">
                                                <option>-select-</option>
                                                {this.state.stListClassMaster.map((item,index)=>{
                                                  return <option value={item["id"]}>{item["class_name"]}</option>
                                                })}

                                              </select>
                                                
                                            </div>
                                        </div>
                          </div> 
                          
             
            </div>
            {/* address */}
            
          </div>

        </div>
        
                            {/* end of other details */}                           

                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Save and Review</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default Student_Admission;