import {React,useEffect} from 'react'


export function Comp_Class_Fee_Master(props){
    //alert(JSON.stringify(props.lsFeeBreakups));
    useEffect(() => {
        if(typeof props.childBtnFeeBreakups==="undefined"){
            return;
        }
        const clsClassFee =props.childBtnFeeBreakups;
        clsClassFee.setOnChangeEvent("fee_breakdown",clsClassFee);
        clsClassFee.purchaseItem.tt=[];
        for(let i=0;i<props.lsFeeBreakups.length;i++){
            let exFeeBreakups = {};
            exFeeBreakups["fee_category_name"] = props.lsFeeBreakups[i]["category_name"];
            exFeeBreakups["install_amount"]=props.lsFeeBreakups[i]["install_amount"];
            exFeeBreakups["no_installment"]=props.lsFeeBreakups[i]["no_installment"];
            exFeeBreakups["recurrence"]=props.lsFeeBreakups[i]["recurrence"];
            exFeeBreakups["total_amount"]=props.lsFeeBreakups[i]["total_amount"];
            exFeeBreakups["id"]=props.lsFeeBreakups[i]["id"];
            exFeeBreakups["fee_category_id"]=props.lsFeeBreakups[i]["fee_category_id"];
            clsClassFee.purchaseItem.tt.push(exFeeBreakups);    
        }
        //alert("i m in porps");

        //alert(clsClassFee.purchaseItem.tt.length+","+clsClassFee.getValue().length);

        clsClassFee.setExistingData();

        clsClassFee.preEventSetData=function setTotalAmount(){
            let totalAmount=0;
            for(let i=0;i<clsClassFee.purchaseItem.tt.length;i++){
                totalAmount+=parseFloat(clsClassFee.purchaseItem.tt[i]["total_amount"]);
                document.getElementById("net_amount").value=totalAmount;
            }
            document.getElementById("net_amount").value=totalAmount;
        }
        clsClassFee.preEventSetData();
      }, [props.lsFeeBreakups]);
    
    return(<div className="modal fade bd-example-modal-xl"  id="divClassFeeMaster" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Class Fee Master</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
            <form class="form-group" id="frmClassFeeMaster" name="frmClassFeeMaster">
                {props.insertFeeMode===true?<div>
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="createClsFeeMaster" />
                <input type="hidden" name="m_type" id="m_type" value="createClsFeeMaster" />
                </div>:<div>
                <input type="hidden" name="res_func" id="res_func" value="fee_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="editClsFeeMaster" />
                <input type="hidden" name="m_type" id="m_type" value="editClsFeeMaster" />
                <input type="hidden" name="e_fee_master_id" id="e_fee_master_id" value={btoa(props.selectedFeeItem.id)}/>
                    </div>}
                <input type="hidden" name="e_invoice_type_id" id="e_invoice_type_id" value="0"/>
                <input type="hidden" name="collection_status" id="collection_status" value="Pending Accession" />
                <input type="hidden" name="e_ref_id" id="e_ref_id" />
                <input type="hidden" name="e_party_id" id="e_party_id"/>

                <input type="hidden" name="unit_id" id="unit_id" value="3" />
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">

                            <div class="card-body">
                            <div class="row">
                                
<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
<label for="exampleInputEmail1">Branch</label>
         <div className="input-group">
               <select id="branch_id" name="branch_id" className="form-control form-control-sm required">
               {props.lsBranches.length>1?<option value={btoa("0")}>-Select-</option>:''}
                {props.lsBranches.map((item,index)=>{
                  return <option selected={props.selectedFeeItem.branch_id===item["id"]} value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="session_year_id" name="session_year_id" required_mg="Select session year" class="form-control select2 required" style={{"width":"100%"}}>
                                                    <option selected value="0">-Select-</option>
                                                        {props.lsSessionYear.map((item,index)=>{
                                                            return <option selected={props.selectedFeeItem.session_year_id===item["id"]} value={item["id"]}>{item["session_name"]}</option>
                                                        })}
                                                        
                                                    </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Class Name</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="class_id" name="class_id" required_mg="Select session year" class="form-control select2 required" style={{"width":"100%"}}>
                                                        <option value="0">-Select-</option>
                                                        {props.lsClassMaster.map((item,index)=>{
                                                            return <option selected={props.selectedFeeItem.class_id===item["id"]} value={item["id"]}>{item["class_name"]}</option>
                                                        })}
                                                    </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="row">
                                    <table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Fee Category</th>
                                                <th>Installment Amount</th>
                                                <th>No of Installment</th>
                                                <th>Recurrence</th>
                                                <th>Total Amount</th>
                                                <th style={{"width":"40px"}}></th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <select id="fee_category_name" name="fee_category_name" val_memeber="fee_category_id" required_mg="Select fee category" class="form-control piMember miMember required1">
                                                            <option value="">Select</option>
                                                          {props.lsFeeCategory.map((item,index)=>{
                                                            return <option value={item["id"]}>{item["category_name"]}</option>
                                                          })}
                                                        </select>
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="install_amount" name="install_amount" required_mg="Installment Amount Required" relate_control="no_installment" output_to="total_amount"  class="form-control cd_input_change piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="no_installment" name="no_installment" required_mg="No of Installment Required" relate_control="install_amount" output_to="total_amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm">
                                                    
                                                    <select id="recurrence" name="recurrence" required_mg="Select recurrence" class="form-control piMember miMember">
                                                        <option value={""}>-select rec.-</option>
                                                        <option value={"One Time"}>One Time</option>
                                                        <option value={"Yearly"}>Yearly</option>
                                                        <option value={"Monthly"}>Monthly</option>
                                                        
                                                    </select>
                                                </div></td>
                                                
                                                
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" readOnly={"true"} id="total_amount" name="total_amount" class="form-control piMember miMember" />
                                                        <input type="hidden" id="fee_category_id" name="fee_category_id" class="piHidMember miMember" value="0" />
                                                        <input type="hidden" id="id" name="id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                </td>
                                                <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_feeCategoryList" resname="tbFeeCatList" id="_btnFeeBreakdown" value="add" /></td>
                                            </tr>
                                        </thead>
                                        <tbody id="tbFeeCatList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_feeCategoryList" name="p_feeCategoryList" value="" />
                                </div>
                                <div class="row">
                                    <div class="col-md-9">
                                    </div>
                                    <div class="col-md-3">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    
                                                    <th>Total Payable</th>
                                                    
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm"><input id="net_amount" readOnly={"true"} rfnode="value" name="net_amount" type="text" class="form-control" /></div></td>
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-primary _btnSetColor">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Class_Fee_Master;