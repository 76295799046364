import React from 'react';
export function Comp_View_Invoice_Breakup(props){
    //alert(JSON.stringify(props));
    return(<div className="modal fade bd-example-modal-xl"  id="divViewInvoiceBreakup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl111" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Invoice Details</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmViewInvBreakup" name="frmViewInvBreakup">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                             
                              <div className="card-body">
                                  <div className="row">
                                  
                                      <div className="col-lg-12 col-md-12">
                                      <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr class="white" style={{"background-color":"#FC766AFF"}}>
                                                <td>Fee Category</td>
                                                <td>Add/Deduct</td>
                                                <td>Amount</td>
                                                <td></td>
                                                </tr>
                                            </thead>
                                          <tbody>
                                            {props.invoiceBreakdown.map((item,index)=>{
                                                return <tr>
                                                    <td>{item.ledger_name}</td>
                                                    <td>{item.add_deduct}</td>
                                                    <td>{item.net_amount}</td>
                                                    <td></td>
                                                </tr>
                                            })}
                                          </tbody>    
                                    </table>
                                      </div>
                                  </div>
                                
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          {/* <button type="button" className="btn _btnSetColor btn-primary">Add New Year</button> */}
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_View_Invoice_Breakup;