import $, { extend } from 'jquery';
import {React,useEffect} from 'react'
import {btnSetColor_CBack,TableDatePicker,FormatDate} from '../function_lib/common_lib';


export function Comp_Student_Transport_Mapp(props){
    //alert(JSON.stringify(props.lsStudentBasic));
    useEffect(()=>{
        if(props.lsStudentBasic.length>0){
            
            if(props.lsStudentBasic[0]["admit_date"]!==null){
                let strAdmitDate=FormatDate(props.lsStudentBasic[0]["admit_date"]);
                $("#admit_date").val(strAdmitDate);
            }
            if(props.lsStudentBasic[0]["dob"]!==null){
                let strDob=FormatDate(props.lsStudentBasic[0]["dob"]);
                $("#edit_dob").val(strDob);
            }
            
            
        }

    },[props.studentId,props.lsStudentBasic])
    return(<div className="modal fade bd-example-modal-xl"  id="divStuTransMapp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Transport Details :{props.editItem.student_name}</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
        {props.lsStudentBasic.length>0?
            <form class="form-group" id="frmStuTransMapp" name="frmStuTransMapp">
                <input type="hidden" name="res_func" id="res_func" value="stubasic_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="updateStuTransport" />
                <input type="hidden" name="m_type" id="m_type" value="updateStuTransport" />
                <input type="hidden" name="stu_id" id="stu_id" value={btoa(props.lsStudentBasic[0]["id"])}/>
                <input type="hidden" name="collection_status" id="collection_status" value="Pending Accession" />
                <input type="hidden" name="e_ref_id" id="e_ref_id" />
                <input type="hidden" name="e_party_id" id="e_party_id"/>

               
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">
                   
                            <div class="card-body">
                           {/* start card-body */}
                           <div className="row">
                                
                               
                                <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Transportation *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="transport_id" name="transport_id" className="form-control required">
                                                  <option value="0">-Select-</option>
                                                  {props.lsTransportList.map((item,index)=>{
                                                    return <option selected={props.lsStudentBasic[0]["transport_id"]===item["id"]} value={btoa(item.id)} >{item["vehicle_type"]}-{item["vehicle_number"]}</option>
                                                  })}
                                               
                                                  {/* {this.state.stReceiptAmount.map((item,index)=>{
                                                    return <option value={item["id"]+","+item["amount"]}>{item["name"]}</option>
                                                  })} */}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                
                                </div>
                                
                        
                      
                           {/* edn of card-body */}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" onClick={()=>props.editStudentBasic("frmStuTransMapp")} class="btn btn-primary _btnSetColor11">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>:''}
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Student_Transport_Mapp;