import React from 'react';
import { Link } from 'react-router-dom';

export function Comp_Link_Acccess11(props){
 
  return(<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled">Disabled</a>
        </li>
      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>)
}
export function Comp_Link_Acccess(props){
  //alert(JSON.stringify(props.roles));
    var getMenu="";
    //const listFltr = d => ((d.display_option === 'menu' || d.display_option === 'top_menu') && ( d.parent_id==='0' || d.parent_id==='1'));
    const listFltr = d => d.top_id===0;
    var topMenusList = props.roles.filter(listFltr);
    //alert(topMenusList.length);
    // alert(JSON.stringify(topMenusList));
    return(<>
    {topMenusList.map((item,index)=>{
      const childFltr = d => d.top_id === item["id"]
       //const childFltr = d => d.top_id === item["id"] && d.display_option !== 'desk'
       let childMenuList = props.roles.filter(childFltr);
       let getChMenu = "";
      if(childMenuList.length===0){
        return(
          <li class="nav-item  d-sm-inline-block">
            <Link class="nav-link nav-menu-top-item" to={"../"+item["module_url"]}>
              {item["module_name"]}
            </Link>
            </li>
          )
      }else{
        return(<li class="nav-item dropdown">
          <a class="nav-link nav-menu-top-item dropdown-toggle" href = "javascript:void(0)" id={"navbarDropdown_m"+index} data-target={"#navbarDropdown"+index} role="button" data-toggle="dropdown" data-bs-toggle="dropdown" aria haspopup="true" aria-expanded="false">{item["module_name"]}</a>
           {/* <div id={"navbarDropdown"+index} class="dropdown-menu" aria-labelledby="navbarDropdown"> */}
           <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            {childMenuList.map((childItem,childIndex)=>{
              return(
                <Link  class="dropdown-item" to={"../"+childItem["module_url"]}>{childItem["module_name"]}</Link>
              )
            })}
            </ul>
        </li>)
      }
     
    })}
    </>
    )
  
  }
  export default Comp_Link_Acccess;